import React from 'react'
import ReactApexChart from 'react-apexcharts';

const Chart3 = ({height,labels,series}) => {
  let totalBudget = series?.map((data)=>data.TotalBudget)
  let totalAllocated	 = series?.map((data)=>data.TotalAllocated)
  let totalSpent	 = series?.map((data)=>data.TotalSpent)
  console.log(totalBudget)
    let state =  {
      series: [100,((totalAllocated/totalBudget)*100),((totalSpent/totalAllocated)*100)],
      options: {
        chart: {
          height: 390,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              }
            }
          }
        },
        colors: ['#1ab7ea', '#0084ff', '#39539E'],
        labels: labels,
        legend: {
          show: true,
          floating: true,
          fontSize: '15px',
          position: 'left',
          offsetX: 0,
          offsetY: 0,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0
          },
          formatter: function(seriesName, opts) {
            //return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
            return seriesName
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
                show: false
            }
          }
        }]
      },
    
    
    };

  return (
    <>
    <div id="chart">
  <ReactApexChart options={state.options} series={state.series} type="radialBar" height={height} />
</div>
    </>
  )
}

export default Chart3