import React from 'react'
import ReactApexChart from 'react-apexcharts';

const LineBarChart = ({data}) => {

  console.log(data);
    let state = {
          
      series: [{
        name: 'Actual',
        type: 'column',
        data: data?.map((data)=>data.Actual||data.Allocated)
      }, {
        name: 'Forecasted',
        type: 'column',
        data: data?.map((data)=>data.Forecasted||data.Budgeted||data.Spent)
      },
      //  {
      //   name: '',
      //   type: 'line',
      //   data: data?.map((data)=>data.Actual||data.Allocated)
      // }
    ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
                  toolbar: {

                      show: false,

                  }

        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        xaxis: {
          categories: ['Apr', 'May', 'Jun', 'July', 'Aug','Sep','Nov','Dec'],

        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#008FFB',
              }
            },
            title: {
              text: "Actual (thousand crores)",
              style: {
                color: '#008FFB',
              }
            },
            tooltip: {
              enabled: true
            }
          },
          {
            seriesName: 'Income',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#00E396'
            },
            labels: {
              style: {
                colors: '#00E396',
              }
            },
            title: {
              text: "Forecasted (thousand crores)",
              style: {
                color: '#00E396',
              }
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60
          },
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    };
  return (
    <>
       <div id="chart">
  <ReactApexChart options={state.options} series={state.series} type="line" height={350} />
</div>
    </>
  )
}

export default LineBarChart
