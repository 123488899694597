import React from "react";

import Chart3 from "../charts/Chart3";

const Widget4 = () => {
  const [totalBudget, setTotalBudget] = React.useState(null);
  const [budgetSpent, setBudgetSpent] = React.useState(null);

  const fetchDataQ1 = React.useCallback(() => {
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/budget-spent-vs-allocated-vs-total/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setTotalBudget(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/budget-spent-vs-allocated-vs-total/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setBudgetSpent(data);
      });
  }, []);
  React.useEffect(() => {
    fetchDataQ1();
  }, [fetchDataQ1]);
  return (
    <>
      <div>
        <div className="row row-cards">
          <div className="col">
            <div class="spentvsAllocated">
              <span class="sp-1"> Total Budget</span>{" "}
              <span class="sp-2"> vs </span> <span class="sp-3">Allocated</span>{" "}
              <span class="sp-2"> vs </span>{" "}
              <span class="sp-4">Total Spent</span>
            </div>
            <div className="card card-sm">
              <div className="card-body">
                <div className="row align-items-top ">
                  {/* <div className="col">
                    <span className="bg-primary text-white avatar h1 me-2">
                      ₹
                    </span>{" "}
                  </div> */}
                  <div className="col-6 mt-5">
                    <table className="table">
                      <thead>
                        <tr>
                          {budgetSpent?.cols.map((data, i) => (
                            <React.Fragment key={i}>
                              <th>{data.colDisplayName}</th>
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      <tbody>

                      {budgetSpent?.rows.map((data, i) => (
                        <tr key={i}>
                          <td>
                            {data.TotalBudget &&
                              "₹" +
                              Number(data.TotalBudget).toLocaleString(
                                "en-IN"
                                )}
                          </td>
                          <td>
                            {data.TotalAllocated &&
                              "₹" +
                              Number(data.TotalAllocated).toLocaleString(
                                "en-IN"
                                )}
                          </td>
                          <td>
                            {data.TotalSpent &&
                              "₹" +
                              Number(data.TotalSpent).toLocaleString("en-IN")}
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-6">
                    <Chart3
                      height={400}
                      labels={[
                        "Total Budget",
                        "Total Allocated",
                        "Total Spent",
                      ]}
                      series={budgetSpent?.rows}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Widget4;
