import React from "react";
import LineChart from "../charts/LineChart";
import LineBarChart from "../charts/LineBarChart";

const Widget10 = () => {
  const [budgetVariance, setBudgetVariance] = React.useState(null);
  const month = [
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Nov",
    "Dec"
  ];
  const fetchDataQ1 = React.useCallback(() => {
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/budget-variance-over-time/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setBudgetVariance(data);
      });
  }, []);
  React.useEffect(() => {
    fetchDataQ1();
  }, [fetchDataQ1]);
  // console.log("budgetVariance",budgetVariance)
  return (
    <>
      <div>
        <div className="row row-cards ">
          <div className="col">
            <div class="spentvsAllocated">
              <span class="sp-5">Budget Variance Over Time </span>
            </div>
            <div className="card card-sm">
              <div className="card-body">
                <div className="row align-items-top">
                  <div className="col-6 mt-5">
                    <table className="table">
                      <thead>
                        <tr>
                          {budgetVariance?.cols.map((data, i) => (
                            <React.Fragment key={i}>
                              <th>{data.colDisplayName}</th>
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {budgetVariance?.rows.map((data, i) => (
                          <tr key={i}>
                            <td>{month[data.Month - 1]}</td>
                            <td>
                              {data.Budgeted &&
                                "₹" +
                                  Number(data.Budgeted).toLocaleString("en-IN")}
                            </td>
                            <td>
                              {data.Actual &&
                                "₹" +
                                  Number(data.Actual).toLocaleString("en-IN")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col">
                    <LineBarChart data={budgetVariance?.rows} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Widget10;
