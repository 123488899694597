import React from "react";
import BarChart from "../charts/BarChart";

const Widget5 = () => {
  const [tendersFloated, setTendersFloated] = React.useState(null);
  const [tendersFinalized, setTendersFinalized] = React.useState(null);
  const [tendersCancelled, setTendersCancelled] = React.useState(null);

  const fetchDataQ1 = React.useCallback(() => {
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/tenders-floated/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setTendersFloated(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/tenders-finalized/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setTendersFinalized(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/tenders-cancelled/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setTendersCancelled(data);
      });
  }, []);
  React.useEffect(() => {
    fetchDataQ1();
  }, [fetchDataQ1]);
  return (
    <>
      <div className="widget5 mt-3">
          <div className="row ">
                  <div className="col">
                    <div className="tendersBox">
                    <div className="col">
                    <h1> Tenders Floated</h1>
                    <hr className="m-0 mb-3"/>
                    <div className="col">
                      <div className="row">
                        <div className="col-12">
                        <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {tendersFloated?.cols.map((data, i) => (
                          <div
                            className={`font-weight-medium ${data.colName}`}
                            key={i}
                          >
                            {data.colDisplayName}
                          </div>
                        ))}
                      </div>
                      {tendersFloated?.rows.map((data, i) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          key={i}
                        >
                          <div className="h2 font-weight-medium TotalAllocated">
                            {data.TenderCount}
                          </div>
                          <div className="h2 font-weight-medium text-center TotalSpent">
                            {data.TotalAmount&&'₹'+Number(data.TotalAmount).toLocaleString('en-IN')}
                          </div>
                        </div>
                      ))}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      {tendersFloated?.rows.map((data,i)=>
                      <React.Fragment key={i}>
                      <BarChart TenderAmounts = {data.TenderAmounts}/>
                      </React.Fragment>
                      )}
                    </div>
                  </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="tendersBox">
                    <div className="col">
                    <h1>Tenders Closed/Finalized</h1>
                    <hr className="m-0 mb-3"/>
                    <div className="col">
                      <div className="row">
                        <div className="col-10">
                        <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {tendersFinalized?.cols.map((data, i) => (
                          <div
                            className={`font-weight-medium ${data.colName}`}
                            key={i}
                          >
                            {data.colDisplayName}
                          </div>
                        ))}
                      </div>
                      {tendersFinalized?.rows.map((data, i) => (
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          key={i}
                        >
                          <div className="h1 font-weight-medium TotalAllocated">
                            {data.TenderCount}
                          </div>
                          <div className="h1 font-weight-medium text-center TotalSpent">
                            {data.TotalAmount&&'₹'+Number(data.TotalAmount).toLocaleString('en-IN')}
                          </div>
                        </div>
                      ))}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      {/* <BarChart TenderAmounts = {tendersFinalized?.rows}/> */}
                      {tendersFinalized?.rows.map((data,i)=>
                      <React.Fragment key={i}>
                      <BarChart TenderAmounts = {data.TenderAmounts}/>
                      </React.Fragment>
                      )}
                    </div>
                  </div>
                    </div>
                  </div>
                  <div className="col">
<div className="tendersBox">
<div className="col">
                    <h1>Tenders Cancelled</h1>
<hr className="m-0 mb-3"/>
                      <div className="col">
                      <div className="row">
                        <div className="col-10">
                        <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {tendersCancelled?.cols.map((data, i) => (
                          <div
                            className={`font-weight-medium ${data.colName}`}
                            key={i}
                          >
                            {data.colDisplayName}
                          </div>
                        ))}
                      </div>
                      {tendersCancelled?.rows.map((data, i) => (
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          key={i}
                        >
                          <div className="h1 font-weight-medium TotalAllocated">
                            {data.TenderCount}
                          </div>
                          <div className="h1 font-weight-medium text-center TotalSpent">
                            {data.TotalAmount&&'₹'+Number(data.TotalAmount).toLocaleString('en-IN')}
                          </div>
                        </div>
                      ))}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      {/* <BarChart  TenderAmounts = {tendersCancelled?.rows}/> */}
                      {tendersCancelled?.rows.map((data,i)=>
                      <React.Fragment key={i}>
                      <BarChart TenderAmounts = {data.TenderAmounts}/>
                      </React.Fragment>
                      )}
                    </div>
                  </div>
</div>

                  </div>
        </div>
      </div>
    </>
  );
};

export default Widget5;
