import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ TenderAmounts }) => {
  // console.log(TenderAmounts)
  // const data = TenderAmounts?.map((data)=>{return data.TenderAmounts})
  // console.log("TenderAmounts",data)
  let state = {
    series: [
      {
        name: "Tender Amounts",
        type: "column",
        data: TenderAmounts,
      },
    ],
    options: {
      chart: {
        type: "bar",
        fontFamily: 'inherit',
        height: 40.0,
        sparkline: {
          enabled: true
        },
        animations: {
          enabled: false
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        }
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },

      tooltip: {
        theme: 'dark'
      },
      grid: {
        strokeDashArray: 4,
      },
      xaxis: {
        labels: {
          padding: 0,
        },
        tooltip: {
          enabled: false
        },
        axisBorder: {
          show: false,
        },
        type: 'datetime',
      },
      yaxis: {
        labels: {
          padding: 4
        },
      },
      labels: TenderAmounts,
      // colors: [tabler.getColor("primary")],
      legend: {
        show: false,
      },
    },
  };
  return (
    <>
      <div id="chart-active-users">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="line"
          height={40}
          width={'100%'}
        />
      </div>
    </>
  );
};

export default BarChart;

