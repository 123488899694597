import React from "react";
import MeterChart from "../charts/MeterChart";

const Widget3 = () => {
  const [dataQ1, setDataQ1] = React.useState(null);
  const [Spent, setSpent] = React.useState("");
  const fetchDataQ1 = React.useCallback(() => {
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/budget-spent-vs-total/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        {
          data?.rows.map((data, i) =>
            setSpent((data.TotalSpent / data.TotalBudget) * 100)
          );
        }
        setDataQ1(data);
      });
  }, []);
  React.useEffect(() => {
    fetchDataQ1();
  }, [fetchDataQ1]);
  // console.log("Spent",Spent)
  return (
    <>
      <div>
        <div className="row row-cards" style={{display:'none'}}>
          <div className="col">
            <div className="card card-sm">
              <div className="card-body">
                <div className="row align-items-center">
                  {/* <h1>Total Budget Spent F.Y. vs Total Budget</h1> */}
                  <div className="col-auto">
                  <span className="bg-primary text-white avatar h1 me-2">
                    ₹
                  </span>{" "}
                  </div>
                  <div className="col">
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {dataQ1?.cols.map((data, i) => (
                        <div
                          className={`h1 font-weight-medium ${data.colName}`}
                          key={i}
                        >
                          {data.colDisplayName}
                        </div>
                      ))}
                    </div>

                    {dataQ1?.rows.map((data, i) => (
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={i}
                      >
                        <div className="h1 font-weight-medium TotalAllocated">
                          {data.TotalBudget === 0
                            ? "To be allocated in Q1"
                            :"₹"+ Number(data.TotalBudget).toLocaleString("en-IN")}
                        </div>
                        <div className="h1 font-weight-medium text-center TotalSpent">
                          {data.TotalSpent === 0
                            ? "To be allocated in Q1"
                            :"₹"+ Number(data.TotalSpent).toLocaleString("en-IN")}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <MeterChart series={[Spent]} width={500} height={550} />
                  </div>
                  {/* <div className="col">
                    <MeterChart series={[Spent]} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Widget3;
