import React from "react";

import HeadsChart from "../charts/HeadsChart";

const Widget9A = () => {
  return (
    <>
      <div>
        <div className="row row-cards">
          <div className="col">
            <div class="spentvsAllocated">
              <span class="sp-5">Top 5 Heads by Expenditure</span>
            </div>
            <div className="card card-sm">
              <div className="card-body">
                <div className="row align-items-center">
                
                  <div className="col-6">
                  <table class="table">
                    <thead>
                        <tr>
                            <th>Heads Name</th>
                            <th>Expenditure</th>
                       </tr>
                       </thead>
                       <tbody>
                        <tr>
                            <td>Salary</td>
                            <td>₹100,000</td>
                        </tr>
                        <tr>
                                <td>Maintenance</td>
                                <td>₹700,000</td>
                        </tr>
                            <tr>
                                <td>Travelling</td>
                                <td>₹190,000</td>
                            </tr>
                            <tr>
                                <td>Bonus</td>
                                <td>₹240,000</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                  <div className="col">
                    <HeadsChart
                      lables={[
                        "Salary",
                        "Maintenance",
                        "Travelling",
                        "Bonus",
                      ]}
                      series={[100000,700000,190000,240000]
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Widget9A;
