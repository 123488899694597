import React from 'react'
import ReactApexChart from 'react-apexcharts';

const PieChart = ({lables,series}) => {
console.log("series",(series))
   let  state = {
        series: series,
        options: {
          chart: {
            width: 380,
            type: 'pie',
          },
          labels:lables,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      
      
      };
  return (
    <>
    
    <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="pie" width={500} />
    </div>
    
    </>
  )
}

export default PieChart