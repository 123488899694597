import React from "react";

const Widget2 = () => {
  const [totalBudget, setTotalBudget] = React.useState(null);
  const [amountUnallocated, setAmountUnallocated] = React.useState(null);
  const [budgetSpent, setBudgetSpent] = React.useState(null);

  const fetchDataQ1 = React.useCallback(() => {
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/total-budget/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setTotalBudget(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/amount-unallocated/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setAmountUnallocated(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/budget-spent-vs-total/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setBudgetSpent(data);
      });
  }, []);
  React.useEffect(() => {
    fetchDataQ1();
  }, [fetchDataQ1]);
  return (
    <>
      <div className="row" style={{display:'none'}}>
        <div className="col" style={{background:'#206bc4'}}>
          <div className="card mb-2 mt-2">
            <div className="card-body">
              <div className="d-flex align-items-center">
                {totalBudget?.cols.map((data, i) => (
                  <React.Fragment key={i}>
                    <div className="h1 mb-3">{data.colDisplayName}</div>
                  </React.Fragment>
                ))}
              </div>
              {totalBudget?.rows.map((data, i) => (
                <React.Fragment key={i}>
                  <div className="h1 mb-3">
                    ₹{Number(data.TotalBudget).toLocaleString("en-IN")}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card mb-2 mt-2">
            <div className="card-body">
              <div className="d-flex align-items-center">
                {/* {budgetSpent?.cols.map((data, i) => (
                  <React.Fragment key={i}>
                    {console.log(data)} */}
                    <div className="h1 mb-3">{budgetSpent?.cols[1].colDisplayName}</div>
                  {/* </React.Fragment>
                ))} */}
              </div>
              {budgetSpent?.rows.map((data, i) => (
                <React.Fragment key={i}>
                  <div className="h1 mb-3">
                    ₹{Number(data.TotalSpent).toLocaleString("en-IN")}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card mb-2 mt-2">
            <div className="card-body">
              <div className="d-flex align-items-center">
                {amountUnallocated?.cols.map((data, i) => (
                  <React.Fragment key={i}>
                    <div className="h1 mb-3">{data.colDisplayName}</div>
                  </React.Fragment>
                ))}
              </div>
              {amountUnallocated?.rows.map((data, i) => (
                <React.Fragment key={i}>
                  <div className="h1 mb-3">
                    ₹{Number(data.Unallocated).toLocaleString("en-IN")}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Widget2;
