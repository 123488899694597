import React from "react";
import Widget1 from "../components/Widget/Widget1";
import Widget2 from "../components/Widget/Widget2";
import Widget3 from "../components/Widget/Widget3";
import Widget4 from "../components/Widget/Widget4";
import Widget5 from "../components/Widget/Widget5";

import Widget12 from "../components/Widget/Widget12";
import Widget10 from "../components/Widget/Widget10";
import Widget9 from "../components/Widget/Widget9";
import Widget8 from "../components/Widget/Widget8";
import Widget9A from "../components/Widget/Widget9A";
const Dashboard = ({ dark }) => {
  return (
    <>
      <Widget1 dark={dark} />
      <Widget2 dark={dark} />
      <Widget3 dark={dark} />
      <Widget4 dark={dark} />
      <Widget5 dark={dark} />
      <Widget8 dark={dark} />
      <Widget9 dark={dark} />
      <Widget9A dark={dark} />
      <Widget10 dark={dark} />
      <Widget12 dark={dark} />
    </>
  );
};

export default Dashboard;
