import React from "react";

import PieChart from "../charts/PieChart";

const Widget9 = () => {
  const [topDepartments, setTopDepartments] = React.useState(null);

  const fetchDataQ1 = React.useCallback(() => {
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/top-departments-by-expenditure/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        // console.log(data?.rows);
        setTopDepartments(data);
      });
  }, []);
  React.useEffect(() => {
    fetchDataQ1();
  }, [fetchDataQ1]);
  return (
    <>
      <div>
        <div className="row row-cards">
          <div className="col">
            <div class="spentvsAllocated">
              <span class="sp-5">Top 5 Departments by Expenditure</span>
            </div>
            <div className="card card-sm">
              <div className="card-body">
                <div className="row align-items-center">
                
                  <div className="col-6">
                        <table className="table">
                          <thead>
                            <tr>
                              {topDepartments?.cols.map((data, i) => (
                                <React.Fragment key={i}>
                                  <th>{data.colDisplayName}</th>
                                </React.Fragment>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {topDepartments?.rows.map((data, i) => (
                              <tr key={i}>
                                <td>{data.DepartmentName}</td>
                                <td>
                                  {data.Expenditure &&
                                    "₹" +
                                      Number(data.Expenditure).toLocaleString(
                                        "en-IN"
                                      )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                  </div>
                  <div className="col">
                    <PieChart
                      lables={[
                        "Department of Infrastructure",
                        "Department of Health",
                        "Department of Technology",
                      ]}
                      series={
                        topDepartments?.rows.map((data, i) =>
                          Number(data.Expenditure)
                        )
                          ? topDepartments?.rows.map((data, i) =>
                              Number(data.Expenditure)
                            )
                          : [0]
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Widget9;
