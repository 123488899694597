import React from "react";
import "./App.css";
import Dashboard from "./pages/Dashboard";

function App() {
  const [dark, setDart] = React.useState(false);

  return (
    <>
      <div style={{ padding: "20px", background: dark ? "#151f2c" : "#f2f2f2" }}>
        {/* <div className="" style={{ textAlign: "right", margin: "5px" }}>
          <button
            onClick={() => setDart(!dark)}
            type="button"
            className={`${dark ? "btn btn-light" : "btn btn-dark"} `}
          >
            {dark ? "Light" : "Dark"}
          </button>
        </div> */}
        <Dashboard dark={dark} />
      </div>
    </>
  );
}

export default App;
