import React from "react";
import LineBarChart from "../charts/LineBarChart";

const Widget8 = () => {
  const [monthWise, setMonthWise] = React.useState(null);
  const month = [
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Nov",
    "Dec"
  ];
  const fetchDataQ1 = React.useCallback(() => {
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/month-wise-allocated-vs-spent/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        // console.log(data)
        setMonthWise(data);
      });
  }, []);
  React.useEffect(() => {
    fetchDataQ1();
  }, [fetchDataQ1]);
  return (
    <>
      <div>
        <div className="row row-cards">
          <div className="col">
            <div class="spentvsAllocated">
              <span class="sp-6">Month-wise Allocated</span>{" "}
              <span class="sp-2"> vs </span> <span class="sp-3">Spent</span>
            </div>
            <div className="card card-sm">
              <div className="card-body">
                <div className="row align-items-top ">
                  {/* <div className="col-auto">
                  <span className="bg-primary text-white avatar h1">
                      ₹
                    </span>
                  </div> */}
                  <div className="col-6 mt-5">

                        <table className="table">
                          <thead>
                            <tr>
                              {monthWise?.cols.map((data, i) => (
                                <React.Fragment key={i}>
                                  <th>{data.colDisplayName}</th>
                                </React.Fragment>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {monthWise?.rows.map((data, i) => (
                              <tr key={i}>
                                <td>{month[data.Month - 1]}</td>

                                <td>
                                  {data.Allocated &&
                                    "₹" +
                                      Number(data.Allocated).toLocaleString(
                                        "en-IN"
                                      )}
                                </td>
                                <td>
                                  {data.Spent &&
                                    "₹" +
                                      Number(data.Spent).toLocaleString(
                                        "en-IN"
                                      )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                  </div>
                  <div className="col">
                    <LineBarChart data={monthWise?.rows} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Widget8;
