import React, { useCallback } from "react";
import { Chart1 } from "../charts/Chart1";
import MeterChart from "../charts/MeterChart";

const Widget1 = ({ dark }) => {
  const [dataQ1, setDataQ1] = React.useState(null);
  const [dataQ2, setDataQ2] = React.useState(null);
  const [dataQ3, setDataQ3] = React.useState(null);
  const [dataQ4, setDataQ4] = React.useState(null);
  const [budgetSpentTotal, setBudgetSpentTotal] = React.useState(null);
  const [amountUnallocated, setAmountUnallocated] = React.useState(null);

  const [budgetSpent, setBudgetSpent] = React.useState(null);
  const fetchDataQ1 = useCallback(() => {
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/spent-vs-allocated/2023/1`
    )
      .then((data) => data.json())
      .then((data) => {
        setDataQ1(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/spent-vs-allocated/2023/2`
    )
      .then((data) => data.json())
      .then((data) => {
        setDataQ2(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/spent-vs-allocated/2023/3`
    )
      .then((data) => data.json())
      .then((data) => {
        setDataQ3(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/spent-vs-allocated/2023/4`
    )
      .then((data) => data.json())
      .then((data) => {
        setDataQ4(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/budget-spent-vs-total/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setBudgetSpentTotal(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/budget-spent-vs-total/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setBudgetSpent(data);
      });
    fetch(
      `https://api.mis-financial.companydemo.in/api/dashboard/amount-unallocated/2023`
    )
      .then((data) => data.json())
      .then((data) => {
        setAmountUnallocated(data);
      });
  }, []);
  React.useEffect(() => {
    fetchDataQ1();
  }, [fetchDataQ1]);
  return (
    <>
      <div>
        <div className="row">
          <div className="col-12 headingBox">Financial Year (F.Y. 2023-24)</div>
        
        </div>
        <div className="row">
          <div className="col-6">
            <div
              className="card"
              style={{
                // background: dark ? "#151f2c" : "#fff",
                background: "#206bc4",
                color: dark ? "#fff" : "#000",
              }}
            >
              <div className="card-body" style={{ color: "#fff" }}>
                <div className="d-flex align-items-center">
                  <h1 className="subheader mb-3" style={{ color: "#fff" }}>
                    Total Budget
                  </h1>
                </div>
                <div className="d-flex h1 mb-3 align-items-center">
                  {budgetSpentTotal?.rows.map((data, i) => (
                    <React.Fragment key={i}>
                      <span className="bg-light text-dark avatar h1 me-2">
                        ₹
                      </span>{" "}
                      <h1>
                        {Number(data.TotalBudget).toLocaleString("en-IN")}
                      </h1>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  {/* {budgetSpent?.cols.map((data, i) => (
                  <React.Fragment key={i}>
                    {console.log(data)} */}
                  <div className="subheader mb-3 ">
                    {budgetSpent?.cols[1].colDisplayName}
                  </div>
                  {/* </React.Fragment>
                ))} */}
                </div>
                {budgetSpent?.rows.map((data, i) => (
                  <React.Fragment key={i}>
                    <div className="h1 mb-3 d-flex align-items-center">
                      <span className="bg-primary text-white avatar h1 me-2">
                        ₹
                      </span>{" "}
                      <h1>
                        {" "}
                        {Number(data.TotalSpent).toLocaleString("en-IN")}
                      </h1>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  {amountUnallocated?.cols.map((data, i) => (
                    <React.Fragment key={i}>
                      <div className="subheader mb-3 ">
                        {data.colDisplayName}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                {amountUnallocated?.rows.map((data, i) => (
                  <React.Fragment key={i}>
                    <div className="h1 mb-3 d-flex align-items-center">
                      <span className="bg-primary text-white avatar h1 me-2">
                        ₹
                      </span>{" "}
                      <h1>
                        {" "}
                        {Number(data.Unallocated).toLocaleString("en-IN")}
                      </h1>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div class="spentvsAllocated">
          <span class="sp-7">Allocated</span> <span class="sp-8"> / </span>{" "}
          <span class="sp-9">Spent</span>
        </div>
        <div className="row row-cards">
          <div className="col-sm-3 col-lg-3">
            <div>
              <div className="card card-sm">
                <div className="card-body">
                  <div className="row align-items-center position-relative">
                    <div className="col ">
                      {" "}
                      <h1 className="subheader">
                        {" "}
                        <span className="bg-primary text-white avatar h1">
                          Q1
                        </span>{" "}
                      </h1>
                    </div>
                    <div className="col " style={{ textAlign: "end" }}>
                      {" "}

                      <h1 className="subheader qmonth"> Apr - Jun </h1>
                    </div>
                  </div>
                  <hr className="m-0 mb-3" />
                  <div className="row align-items-center position-relative">
                    <div className="col qDataBox">
                      {dataQ1?.rows.map((data, i) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {data.TotalAllocated !== 0 ? (
                            dataQ1?.cols.map((data, i) => (
                              <div
                                className={` font-weight-medium ${data.colName}`}
                                key={i}
                              >
                                {data.colDisplayName}
                              </div>
                            ))
                          ) : (
                            <h5 style={{ color: "#ba3d5d" }} className="hangon">
                              <img
                                className="hourglass"
                                src="hourglass.png"
                                alt=""
                                width={50}
                                height={50}
                              />
                              Hang on! In due time
                            </h5>
                          )}
                        </div>
                      ))}
                      {dataQ1?.rows.map((data, i) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            visibility: data.TotalAllocated === 0 && "hidden",
                          }}
                          key={i}
                        >
                          <div className="h2 font-weight-medium TotalAllocated">
                            {"₹" +
                              Number(data.TotalAllocated).toLocaleString(
                                "en-IN"
                              )}
                          </div>
                          <div className="h2 font-weight-medium TotalSpent">
                            {"₹" +
                              Number(data.TotalSpent).toLocaleString("en-In")}
                          </div>
                        </div>
                      ))}
                    </div>
                    {dataQ1?.rows?.map((data, i) => (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <React.Fragment key={i}>
                          <MeterChart
                            series={[
                              (data.TotalSpent / data.TotalAllocated) * 100
                                ? (data.TotalSpent / data.TotalAllocated) * 100
                                : 0,
                            ]}
                            height={250}
                            width={300}
                          />
                        </React.Fragment>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-lg-3">
            <div className="card card-sm">
              <div className="card-body">
                <div className="row align-items-center ">
                  <div className="col ">
                    {" "}
                    <h1 className="subheader">
                      {" "}
                      <span className="bg-primary text-white avatar h1">
                        Q2
                      </span>{" "}
                    </h1>
                  </div>
                  <div className="col " style={{ textAlign: "end" }}>
                    {" "}
                    <h1 className="subheader qmonth"> July - Sep </h1>
                  </div>
                </div>
                <hr className="m-0 mb-3" />
                <div className="row align-items-center position-relative">
                  <div className="col qDataBox">
                    {dataQ2?.rows.map((data, i) => (
                      <div>
                        {data.TotalAllocated !== 0 ? (
                          dataQ2?.cols.map((data, i) => (
                            <div
                              className={`font-weight-medium ${data.colName}`}
                              key={i}
                            >
                              {data.colDisplayName}
                            </div>
                          ))
                        ) : (
                          <h5 style={{ color: "#ba3d5d" }} className="hangon">
                            <img
                              className="hourglass"
                              src="hourglass.png"
                              alt=""
                              width={30}
                              height={30}
                            />
                            Hang on! In due time
                          </h5>
                        )}
                      </div>
                    ))}
                    {dataQ2?.rows.map((data, i) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          visibility: data.TotalAllocated === 0 && "hidden",
                        }}
                        key={i}
                      >
                        <div className="h2 font-weight-medium TotalAllocated">
                          {"₹" +
                            Number(data.TotalAllocated).toLocaleString("en-IN")}
                        </div>
                        <div className="h2 font-weight-medium TotalSpent">
                          {"₹" +
                            Number(data.TotalSpent).toLocaleString("en-In")}
                        </div>
                      </div>
                    ))}
                  </div>
                  {dataQ2?.rows?.map((data, i) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        opacity: data.TotalAllocated === 0 && "0.2",
                      }}
                    >
                      <React.Fragment key={i}>
                        <MeterChart
                          series={[
                            (data.TotalSpent / data.TotalAllocated) * 100
                              ? (data.TotalSpent / data.TotalAllocated) * 100
                              : 0,
                          ]}
                          height={250}
                          width={300}
                        />
                      </React.Fragment>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-lg-3">
            <div className="card card-sm">
              <div className="card-body">
                <div className="row align-items-center ">
                  <div className="col ">
                    {" "}
                    <h1 className="subheader">
                      {" "}
                      <span className="bg-primary text-white avatar h1">
                        Q3
                      </span>{" "}
                    </h1>
                  </div>
                  <div className="col " style={{ textAlign: "end" }}>
                    {" "}
                    <h1 className="subheader qmonth"> Oct - Dec </h1>
                  </div>
                </div>
                <hr className="m-0 mb-3" />
                <div className="row align-items-center position-relative">
                  <div className="col qDataBox">
                    {dataQ3?.rows.map((data, i) => (
                      <div>
                        {data.TotalAllocated !== 0 ? (
                          dataQ3?.cols.map((data, i) => (
                            <div
                              className={`font-weight-medium ${data.colName}`}
                              key={i}
                            >
                              {data.colDisplayName}
                            </div>
                          ))
                        ) : (
                          <h5 style={{ color: "#ba3d5d" }} className="hangon">
                            <img
                              className="hourglass"
                              src="hourglass.png"
                              alt=""
                              width={30}
                              height={30}
                            />
                            Hang on! In due time
                          </h5>
                        )}
                      </div>
                    ))}
                    {dataQ3?.rows.map((data, i) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          visibility: data.TotalAllocated === 0 && "hidden",
                        }}
                        key={i}
                      >
                        <div className="h2 font-weight-medium TotalAllocated">
                          {"₹" +
                            Number(data.TotalAllocated).toLocaleString("en-IN")}
                        </div>
                        <div className="h2 font-weight-medium TotalSpent">
                          {"₹" +
                            Number(data.TotalSpent).toLocaleString("en-In")}
                        </div>
                      </div>
                    ))}
                  </div>
                  {dataQ3?.rows?.map((data, i) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        opacity: data.TotalAllocated === 0 && "0.2",
                      }}
                    >
                      <React.Fragment key={i}>
                        <MeterChart
                          series={[
                            (data.TotalSpent / data.TotalAllocated) * 100
                              ? (data.TotalSpent / data.TotalAllocated) * 100
                              : 0,
                          ]}
                          height={250}
                          width={300}
                        />
                      </React.Fragment>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-lg-3">
            <div className="card card-sm">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col ">
                    {" "}
                    <h1 className="subheader">
                      {" "}
                      <span className="bg-primary text-white avatar h1">
                        Q4
                      </span>{" "}
                    </h1>
                  </div>
                  <div className="col " style={{ textAlign: "end" }}>
                    {" "}
                    <h1 className="subheader qmonth"> Jan - Mar </h1>
                  </div>
                </div>
                <hr className="m-0 mb-3" />
                <div className="row align-items-center position-relative">
                  <div className="col qDataBox">
                    {dataQ4?.rows.map((data, i) => (
                      <div>
                        {data.TotalAllocated !== 0 ? (
                          dataQ2?.cols.map((data, i) => (
                            <div
                              className={`font-weight-medium ${data.colName}`}
                              key={i}
                            >
                              {data.colDisplayName}
                            </div>
                          ))
                        ) : (
                          <h5 style={{ color: "#ba3d5d" }} className="hangon">
                            <img
                              className="hourglass"
                              src="hourglass.png"
                              alt=""
                              width={30}
                              height={30}
                            />
                            Hang on! In due time
                          </h5>
                        )}
                      </div>
                    ))}
                    {dataQ4?.rows.map((data, i) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          visibility: data.TotalAllocated === 0 && "hidden",
                        }}
                        key={i}
                      >
                        <div className="h2 font-weight-medium TotalAllocated">
                          {"₹" +
                            Number(data.TotalAllocated).toLocaleString("en-IN")}
                        </div>
                        <div className="h2 font-weight-medium TotalSpent">
                          {"₹" +
                            Number(data.TotalSpent).toLocaleString("en-In")}
                        </div>
                      </div>
                    ))}
                  </div>
                  {dataQ4?.rows?.map((data, i) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        opacity: data.TotalAllocated === 0 && "0.2",
                      }}
                    >
                      <React.Fragment key={i}>
                        <MeterChart
                          series={[
                            (data.TotalSpent / data.TotalAllocated) * 100
                              ? (data.TotalSpent / data.TotalAllocated) * 100
                              : 0,
                          ]}
                          height={250}
                          width={300}
                        />
                      </React.Fragment>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Widget1;
